<script>
import Multiselect from "@vueform/multiselect";
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      countries: [],
      country: null,
      loading: false,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    country(value) {
      this.$emit("input", value);
    },
    value(value) {
      if (!lodash.isEmpty(this.countries)) {
        if (!lodash.isNil(value)) {
          this.country = lodash.find(this.countries, { id: value.id });
        }
      }
    },
  },
  methods: {
    geoCountry() {
      this.loading = true;
      this.$store
        .dispatch("geoCountryGet")
        .then((response) => {
          this.countries = lodash.orderBy(response.data.data, "name");
          if (!lodash.isNil(this.value)) {
            this.country = lodash.find(this.countries, {
              id: this.value.id,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$emit("input", null);
      this.country = null;
    },
  },
  mounted() {
    this.geoCountry();
  },
};
</script>
<template>
  <Multiselect
    key="select-country"
    v-model="country"
    :options="countries"
    :disabled="lodash.isEmpty(countries) || disabled"
    :loading="loading"
    :placeholder="`--${$t('placeholder.select')}--`"
    :searchable="true"
    trackBy="name"
    valueProp="id"
    :classes="{
      search: 'multiselect-search',
    }"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <img
          class="mr-2 rounded character-label-icon"
          style="margin-bottom: 2px; height: 20px"
          :src="require(`@/assets/flags/flag_${value.iso2.toLowerCase()}.png`)"
          :alt="value.iso2"
        />
        {{ value.name }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <img
        class="mr-2 rounded character-label-icon"
        style="margin-bottom: 2px; height: 20px"
        :src="require(`@/assets/flags/flag_${option.iso2.toLowerCase()}.png`)"
        :alt="option.iso2"
      />
      {{ option.name }}
    </template>
  </Multiselect>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect {
  border: none !important;
  background: transparent !important;
  color: white !important;
}
.multiselect.is-active {
  box-shadow: none !important;
}
.multiselect-search,
.multiselect.is-disabled {
  border-radius: 0 !important;
  border-bottom: 1px solid #8991d3 !important;
}
.multiselect-single-label {
  padding-left: 0 !important;
}
</style>